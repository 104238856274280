import axios from 'axios';

export interface City {
  name: string;
  codes: string[];
  logo: string;
  stateId: string;
  text: string;
}

const QUERY_STRING_TS_PARAMETER = 'ts';

export const fetchCities = async (citiesJsonUrl: string): Promise<City[]> => {
  try {
    const now = Date.now();
    const ts = now - (now % (60 * 10 * 1000));
    const response = await axios.get(`${citiesJsonUrl}?${QUERY_STRING_TS_PARAMETER}=${ts}`);

    const cities: City[] = response.data;

    return cities;
  } catch (error) {
    console.error('Error retrieving cities data', error);

    return [];
  }
};
