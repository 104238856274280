export const shouldShowToday = () => {
  try {
    const now = new Date();
    const utcHour = now.getUTCHours();
    const utcWeekday = now.getUTCDay();
    let offset = 5;

    // fix this with moment.js next year if this module still exists
    if (
      (now.getUTCMonth() > 2 && now.getUTCMonth() < 10) ||
      (now.getUTCMonth() === 10 && now.getUTCDate() < 4) ||
      (now.getUTCMonth() === 2 && now.getUTCDate() >= 10)
    ) {
      offset = 4;
    }

    const limitHour = 3 + offset;

    if (utcWeekday === 0 || (utcWeekday === 6 && utcHour >= limitHour) || (utcWeekday === 1 && utcHour < limitHour)) {
      return false;
    }
  } catch (error) {
    // Fail silently
  }

  return true;
};
