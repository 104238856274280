import React from 'react';
import {render} from 'react-dom';
import TrailerModule from '../../shared/components/TrailerModule';
import {shouldShowToday} from '../../shared/components/TrailerModule/showToday';
import * as trailerStyles from '../../shared/components/TrailerModule//styles.css';

const getOffsets = (element: HTMLElement) => {
  let top = element.offsetTop;
  let left = element.offsetLeft;
  let parent = element.offsetParent as HTMLElement;

  while (parent) {
    if (!Number.isNaN(parent.offsetTop) && !Number.isNaN(parent.offsetLeft)) {
      top += parent.offsetTop;
      left += parent.offsetLeft;
    }
    parent = parent.offsetParent as HTMLElement;
  }

  return {
    left,
    top
  };
};

// tslint:disable-next-line:no-any
const debounce = (cb: (...args: any[]) => void, delay = 0) => {
  let timeoutId: number;

  // tslint:disable-next-line:no-any
  return (...args: any[]) => {
    clearTimeout(timeoutId);

    // eslint-disable-next-line promise/prefer-await-to-callbacks
    timeoutId = window.setTimeout(() => cb(...args), delay);
  };
};

interface VideoPlayerElement extends HTMLVideoElement {
  // tslint:disable-next-line:no-any
  player?: any;
}

const init = () => {
  Array.from(document.querySelectorAll('[data-mol-fe-dmtv-trailer-module]')).forEach((element) => {
    const akamaiCityCode = element.getAttribute('data-akamai-citycode');
    const showToday = shouldShowToday();
    const citiesJsonUrl = element.getAttribute('data-dmtv-cities-json');

    if (akamaiCityCode !== null && citiesJsonUrl) {
      render(
        <TrailerModule akamaiCityCode={akamaiCityCode} citiesJsonUrl={citiesJsonUrl} showToday={showToday} />,
        element
      );
    }

    // For some reason client doesn't update classname properly
    const heading = element.querySelector(`.${trailerStyles.heading}`);

    if (heading) {
      if (showToday) {
        heading.classList.remove(trailerStyles.monday);
        heading.classList.add(trailerStyles.today);
      } else {
        heading.classList.add(trailerStyles.monday);
        heading.classList.remove(trailerStyles.today);
      }
    }

    let isInView = false;

    // @ts-ignore
    if (window.DM && window.DM.getPageMetadata().renderPlatform === 'desktop') {
      const scrollHandler = debounce(() => {
        const videoElement: VideoPlayerElement | null = element.querySelector('video');

        if (!videoElement) {
          return;
        }

        const position = window.pageYOffset;
        const viewportHeight =
          (document.documentElement && document.documentElement.clientHeight) || window.innerHeight || 1000;
        const {top} = getOffsets(videoElement);
        const isInsideViewport = position + viewportHeight > top && position < top + videoElement.offsetHeight;

        if (isInsideViewport && !isInView) {
          isInView = true;
          if (videoElement.paused) {
            if (videoElement.currentTime === 0) {
              if (videoElement.player) {
                videoElement.player.muted(true);
              } else {
                videoElement.muted = true;
              }
            }

            if (videoElement.player) {
              videoElement.player.play();
            } else {
              videoElement.play();
            }
          }
        } else if (!isInsideViewport && isInView) {
          isInView = false;
          if (videoElement.paused && videoElement.muted) {
            if (videoElement.player) {
              videoElement.player.pause();
            } else {
              videoElement.pause();
            }
          }
        }
      }, 50);

      window.addEventListener('scroll', scrollHandler);

      // Check on init in case it's already visible
      scrollHandler();
    }
  });
};

export default init;
