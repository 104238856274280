export default {
  control: (base: object) => ({
    ...base,
    cursor: 'pointer'
  }),
  menu: (base: object) => ({
    ...base,
    backgroundColor: 'white',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginTop: 0,
    zIndex: 100000
  }),
  option: (base: object) => ({
    ...base,
    cursor: 'pointer',
    fontSize: '16px'
  }),
  singleValue: (base: object) => ({
    ...base,
    fontSize: '16px'
  })
};
