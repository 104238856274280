import VideoPlayerOptions from '../VideoPlayer/VideoPlayerOptions';

const videoOptions: VideoPlayerOptions = {
  controlBar: {
    isSocialControlsHidden: true
  },
  descr: 'DMTV Trailer',
  grapeshot: {
    article: {
      articleURL: null
    }
  },
  initialVideo: true,
  isMobile: false,
  isTitleHidden: true,
  linkBaseURL: 'https://www.dailymail.co.uk/dailymailtv/index.html',
  playerId: 'default',
  plugins: {
    'ads-setup': {
      adsEnabled: false
    },
    'autoplay-inline': {
      enabled: true
    },
    tracking: {
      channelShortName: 'dailymailtv',
      referenceId: 'dmtvtrailer',
      sponsored: false
    }
  },
  poster: 'https://i.dailymail.co.uk/i/dmtv/video/poster.jpg',
  preload: 'none',
  referenceId: 'dmtvtrailer',
  source: 'DailymailTV',
  src: 'https://i.dailymail.co.uk/i/dmtv/video/trailer.mp4',
  syndicationService: null,
  title: 'DMTV Trailer',
  videoId: 'dmtvtrailer'
};

export default videoOptions;
