import React, {Component} from 'react';
import VideoPlayerOptions from './VideoPlayerOptions';

declare var DM: {
  molFeVideoplayer: {
    initializeVideo: (el: HTMLVideoElement, config: object) => Player;
  };
  getPageMetadata: () => {renderPlatform: string};
};

const isDesktopRender = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  /// @ts-ignore
  // tslint:disable:no-shadowed-variable
  const DM = window.DM;
  if (DM && DM.getPageMetadata && DM.getPageMetadata().renderPlatform) {
    return DM.getPageMetadata().renderPlatform === 'desktop';
  }

  return window.innerWidth > 768;
};

export default class VideoPlayer extends Component<PropTypes> {
  public state: StateInterface;
  public videoElement: React.RefObject<DmHTMLVideoElement>;
  public player: Player | undefined;

  public constructor(props: PropTypes) {
    super(props);

    this.videoElement = React.createRef();
    this.state = {
      windowWidth: 0
    };
  }

  public componentDidMount() {
    const {curVideoOptions} = this.props;

    this.initNewVideo(curVideoOptions);
  }

  public async initNewVideo(curVideoOptions: VideoPlayerOptions) {
    const videoElement = this.videoElement.current;

    const videoOptions = {
      ...curVideoOptions
    };

    if (!videoElement || Object.keys(videoOptions).length === 0) {
      return;
    }

    if (videoOptions.plugins) {
      if (videoOptions.plugins['related-videos']) {
        videoOptions.plugins['related-videos'].playlistHistory = false;
      }

      // TODO: remove this if once ticket MOL-15915 gets released
      if (videoOptions.plugins['animated-preview']) {
        delete videoOptions.plugins['animated-preview'];
      }
    }

    if (this.player) {
      this.player.resetVideo({
        autoplay: videoOptions.forceAutoplay || !this.player.paused(),
        video: videoOptions
      });
    } else if (DM && DM.molFeVideoplayer && DM.molFeVideoplayer.initializeVideo) {
      this.player = await DM.molFeVideoplayer.initializeVideo(videoElement, videoOptions);
      this.player.on('reset', () => {
        const vid = (this.player as Player).options().referenceId as number;

        if (vid !== this.props.vid && typeof this.props.onVideoChanged === 'function') {
          this.props.onVideoChanged(vid, false);
        }
      });
    }
  }

  public componentDidUpdate() {
    const {curVideoOptions} = this.props;

    if (!this.player || curVideoOptions.referenceId !== this.player.options().referenceId) {
      this.initNewVideo(curVideoOptions);
    }
  }

  public render(): JSX.Element {
    const {curVideoOptions, loop, trailer} = this.props;

    return (
      <div
        className={`dailymailtv vjs-video-container ${
          isDesktopRender() || trailer ? 'vjs-span-two-col' : 'vjs-responsive'
        }`}
      >
        <video
          controls
          className="video-js vjs-default-skin"
          loop={loop}
          playsInline
          preload="none"
          ref={this.videoElement}
        >
          <source src={curVideoOptions.src} type="video/mp4" />
        </video>
      </div>
    );
  }
}

interface PropTypes {
  curVideoOptions: VideoPlayerOptions;
  loop?: boolean;
  trailer?: boolean;
  vid?: number;
  onVideoChanged?: (vid: number, scrollIntoView: boolean) => Promise<void>;
}

interface StateInterface {
  windowWidth: number;
}

interface VideoPlayerEvent {
  video: {
    options: {
      referenceId: number;
    };
    referenceId: number;
  };
}

type OnEventTriggeredCallback = (evt: VideoPlayerEvent) => void;

interface Player {
  on: (evtType: string, fn: OnEventTriggeredCallback) => void;
  paused: () => boolean;
  resetVideo: (data: object) => void;
  options: () => VideoPlayerOptions;
}

interface DmHTMLVideoElement extends HTMLVideoElement {
  player: Player;
}
