import React, {Component} from 'react';
import Select from 'react-select';

import * as styles from './styles.css';
import selectStyles from './selectStyles';
const mapMarker = require('./img/map_marker.svg');
import {ValueType} from 'react-select/lib/types';
import {Option as CityOption} from 'react-select/lib/filters';
import {fetchCities, City} from '../../api/cities';

export default class CityFinder extends Component<PropTypes> {
  public state: StateInterface;

  constructor(props: PropTypes) {
    super(props);

    this.state = {
      cities: [],
      cityOption: this.getEmptyCityOption(),
      cityOptions: [],
      isSearcherOpen: false,
      logo: '',
      windowWidth: 0
    };

    this.handleClick = this.handleClick.bind(this);
  }

  public updateWindowDimensions = () => {
    this.setState({windowWidth: window.innerWidth});
  };

  public async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    const citiesJson = await fetchCities(this.props.citiesJsonUrl);

    this.state.cityOption = this.getCityOption(citiesJson, this.props.cityCode);
    this.state.cityOptions = this.getAllCityOptions(citiesJson);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  public handleClick(): void {
    if (!this.state.cityOptions) {
      return;
    }

    this.setState({
      isSearcherOpen: !this.state.isSearcherOpen
    });
  }

  public getAllCityOptions = (cities: City[]) => cities.map((city) => this.getCityOptionFromCity(city));

  public getExactCityOption = (cities: City[], cityCode: string): CityOption | undefined => {
    for (const city of cities) {
      const {codes} = city;
      // set cityOption from cityCode
      if (codes.includes(cityCode)) {
        return this.getCityOptionFromCity(city);
      }
    }

    return;
  };

  public getCityOption = (cities: City[], cityCode: string): CityOption => {
    const cityCodeValues = cityCode.split('-');
    const countryId = cityCodeValues[0];

    if (countryId !== 'US') {
      return this.getEmptyCityOption();
    }

    return this.getExactCityOption(cities, cityCode) || this.getEmptyCityOption();
  };

  public getEmptyCityOption = (): CityOption => ({
    data: {
      city: null,
      logo: '',
      text: ''
    },
    label: 'Select a city',
    value: ''
  });

  public getCityOptionFromCity = (city: City): CityOption => {
    const {name, text, logo, stateId} = city;

    return {
      data: {
        city,
        logo,
        text
      },
      label: `${name} (${stateId})`,
      value: `${name}_${stateId}`
    };
  };

  public handleChange = (option: ValueType<CityOption>) => {
    if (option && !Array.isArray(option)) {
      this.setState({
        cityOption: this.getCityOptionFromCity(option.data.city),
        isSearcherOpen: false
      });
    }
  };

  public render(): JSX.Element | null {
    const {cityOption, cityOptions, isSearcherOpen, windowWidth} = this.state;
    const city = cityOption.data.city;

    return (
      <div className={styles.cityFinder}>
        <div className={styles.twoColContainer}>
          <div className={styles.leftCol}>
            <div className={styles.identityMsg}>
              {city ? <span>{cityOption.data.text}</span> : <span>Find out when the show is on in your area</span>}
            </div>
            <div className={styles.city}>
              <img src={mapMarker} alt="Map marker" />
              {city ? (
                <strong>
                  {city.name} ({city.stateId})
                </strong>
              ) : null}
              {isSearcherOpen ? (
                <span className={styles.changeLocation} onClick={this.handleClick}>
                  Close
                </span>
              ) : (
                <span className={styles.changeLocation} onClick={this.handleClick}>
                  {windowWidth > 768 ? (city ? 'Change location' : 'Choose location') : 'Change'}
                </span>
              )}
            </div>
          </div>
          {city && city.logo && city.logo.toLowerCase() !== 'null' ? (
            <div className={styles.rightCol}>
              <img src={city.logo} />
            </div>
          ) : (
            <div className={styles.rightCol}>
              <img src="https://i.dailymail.co.uk/i/dmtv/stations/DMTV-new.png" />
            </div>
          )}
        </div>
        {isSearcherOpen ? (
          <div className={styles.selectContainer}>
            <Select value={cityOption} onChange={this.handleChange} options={cityOptions} styles={selectStyles} />
          </div>
        ) : null}
      </div>
    );
  }
}

interface PropTypes {
  citiesJsonUrl: string;
  cityCode: string;
}

interface StateInterface {
  cities: City[];
  cityOption: CityOption;
  cityOptions: CityOption[];
  isSearcherOpen: boolean;
  logo: string;
  windowWidth: number;
}
